/* eslint-disable */
import login from '../components/login.vue'
const pagenoutfound = () => import('../components/pagenoutfound.vue')
const home = () => import('../components/home.vue')


export const routes = [
    //Navigation value tells login comp where to navigate to in the home component -> pathname
    { name: 'Nlogin', path: '/', component: login, display: 'Login', icon: 'Login', props: { navigation: "Home", rec_view: false, reg_view: false, help_active: false } },
    { name: 'Ulogin', path: '/Users', component: login, display: 'Login', icon: 'Login', props: { navigation: "UsersHome", rec_view: false, reg_view: false, help_active: false } },
    { name: 'Reclogin', path: '/Recover', component: login, display: 'Login', icon: 'Login', props: { navigation: "Home", rec_view: true, reg_view: false, help_active: false } },
    { name: 'Reglogin', path: '/Register', component: login, display: 'Login', icon: 'Login', props: { navigation: "Home", rec_view: false, reg_view: true, help_active: false } },
    { name: 'Helplogin', path: '/Help', component: login, display: 'Login', icon: 'Login', props: { navigation: "Home", rec_view: false, reg_view: false, help_active: true } },
    //Navigation value determines if apps section or admin section should be rendered first in the home comp
    { name: 'Home', path: '/Home', component: home, display: 'Home', icon: 'Home', props: { navigation: "apps" } },
    { name: 'Admin', path: '/UsersHome', component: home, display: 'Home', icon: 'Home', props: { navigation: "users" } },
    { name: 'not-found', path: '/:pathMatch(.*)*', component: pagenoutfound },

]
