/* eslint-disable */
//<!--RPD Firearm Request App v0.9.9
//Copyright(c) 2019 Blue Cover Six LLC / John Molt
//BCS grants unlimited license to The Reno Police Department and the City of Reno-->

import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading: [],
        loader_visible: true,
        sidenav_visible: false,
        swal_on: false,
        user: {
            email: '',
            roles: '',
            token: '',
            rpduserId: 0,
            local_roleId: -1
        },
        swal_shown: false,
        brandbar_show: true,
        time_remaining_warn_mills: null,
        time_remaining_final_mills: null,
        appURL: "https://rpdportal.org/"


    },
    mutations: {
        setBrandbar_show(state, payload) {
            state.brandbar_show = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setSwal_shown(state, payload) {
            state.swal_shown = payload;
        },
        setSwal_on(state, payload) {
            state.swal_on = payload;
        },
        setSidenav_visible(state, payload) {
            state.sidenav_visible = payload;
        },
        setLoader_visible(state, payload) {
            state.loader_visible = payload;
            setTimeout(function () {
                state.loader_visible = false;
            }, 15000)
        },
        setShow_loader(state, payload) {
            state.loader_visible = payload;
            setTimeout(function () {
                state.loader_visible = false;
            }, 15000)
        },
        setTime_remaining_warn_mills(state, payload) {
            state.time_remaining_warn_mills = payload;

        },
        setTime_remaining_final_mills(state, payload) {
            state.time_remaining_final_mills = payload;

        },
    },
    actions:
    {
        //get_ew_open
        get_ew_open(context, payload) {
            return new Promise((resolve, reject) => {
                Axios.post('api/get_ew_open', payload).then(response => {
                    if (response.status === 200) {
                        // this.commit("setLstPeople", response.data);
                        resolve(response.data);
                    } else {
                        reject(response.data.msg);
                    }
                }).catch(response => {
                    //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
                    reject("Log not found");
                });
            });
        },
        //search
        search(context, payload) {
            return new Promise((resolve, reject) => {
                Axios.post('api/search', payload).then(response => {
                    if (response.status === 200) {
                        // this.commit("setLstPeople", response.data);
                        resolve(response.data);
                    } else {
                        reject(response.data.msg);
                    }
                }).catch(response => {
                    //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
                    reject(response.data.msg);
                });
            });
        },

        //anyapi
        anyapi(context, payload) {
            return new Promise((resolve, reject) => {
                Axios.post('api/' + payload.apiname, payload).then(response => {
                    if (response.status === 200) {
                        // this.commit("setLstPeople", response.data);
                        resolve(response.data);
                    } else {
                        reject(response.data.msg);
                    }
                }).catch(response => {
                    //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
                    reject(response.data.msg);
                });
            });
        },
        //delete_log
        delete_log(context, payload) {
            return new Promise((resolve, reject) => {
                Axios.post('api/delete_log', payload).then(response => {
                    if (response.status === 200) {
                        // this.commit("setLstPeople", response.data);
                        resolve(response.data);
                    } else {
                        reject(response.data.msg);
                    }
                }).catch(response => {
                    //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
                    reject(response.data.msg);
                });
            });
        },
        //save_log
        save_log(context, payload) {
            return new Promise((resolve, reject) => {
                Axios.post('api/save_log', payload).then(response => {
                    if (response.status === 200) {
                        // this.commit("setLstPeople", response.data);
                        resolve(response.data);
                    } else {
                        reject(response.data.msg);
                    }
                }).catch(response => {
                    //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
                    reject(response.data.msg);
                });
            });
        },

        // get_pending_lotlogs
        get_pending_lotlogs(context, payload) {
            return new Promise((resolve, reject) => {
                Axios.post('api/get_pending_lotlogs', payload).then(response => {
                    if (response.status === 200) {
                        // this.commit("setLstPeople", response.data);
                        resolve(response.data);
                    } else {
                        reject(response.data.msg);
                    }
                }).catch(response => {
                    //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
                    reject(response.data.msg);
                });
            });
        },
        //getAll_lotlogs
        getAll_lotlogs(context, payload) {
            return new Promise((resolve, reject) => {
                Axios.post('api/getAll_lotlogs', payload).then(response => {
                    if (response.status === 200) {
                        // this.commit("setLstPeople", response.data);
                        resolve(response.data);
                    } else {
                        reject(response.data.msg);
                    }
                }).catch(response => {
                    //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
                    reject(response.data.msg);
                });
            });
        },

        //submit_password
        submit_password(context, payload) {
            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/submit_password/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Error",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        //submit_code
        submit_code(context, payload) {
            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/submit_code/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Error",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        //send_code
        send_code(context, payload) {
            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/send_code/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Error",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        //saveRegEvent
        saveRegEvent(context, payload) {
            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/saveRegEvent/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Error",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        valtoken(context, payload) {
            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/valtoken/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Error",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        submit_answer(context, payload) {

            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/submit_answer/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Recover Status",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        submit_email_recover(context, payload) {

            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/submit_email_recover/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Recover Status",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        submit_answer(context, payload) {

            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/submit_answer/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Recover Status",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        setNewPassword(context, payload) {

            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/setNewPassword/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);
                        reject(response.data.msg);
                    }
                }).catch(function (error) {
                    Vue.prototype.$swal({
                        title: "Recover Status",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    console.log(error.response.data.msg);
                    context.commit('setLoader_visible', false);
                });
            });


        },
        login_submit_password(context, payload) {


            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/login_user/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);


                        reject(response.data.msg);

                    }
                }).catch(function (error) {
                    console.log(error);
                    context.commit('setLoader_visible', false);
                    Vue.prototype.$swal({
                        title: "Login Error",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                });
            });


        },

        //uploadfile_docs
        uploadfile_docs(context, payload) {
            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/uploadfile_docs/', payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            // console.log("response.data: " + response.data);
                            resolve(response.data);
                        } else {
                            //  console.log("response err: " + response.status);
                            reject(response.data.msg);
                        }
                    })
                    .catch(function (error) {
                        Vue.prototype.$swal({
                            title: "Upload Error ",
                            text: error.response.data.msg,
                            icon: "error",
                            confirmButtonText: "OK"
                        });
                        console.log(error.response.data.msg);
                        context.commit('setLoader_visible', false);
                    });
            });
        },

        //uploadfile_docs
        uploadfile_test(context, payload) {
            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/uploadfile/', payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            // console.log("response.data: " + response.data);
                            resolve(response.data);
                        } else {
                            //  console.log("response err: " + response.status);
                            reject(response.data.msg);
                        }
                    })
                    .catch(function (error) {
                        Vue.prototype.$swal({
                            title: "Upload Error ",
                            text: error.response.data.msg,
                            icon: "error",
                            confirmButtonText: "OK"
                        });
                        console.log(error.response.data.msg);
                        context.commit('setLoader_visible', false);
                    });
            });
        },

        email_test(context, payload) {


            return new Promise((resolve, reject) => {
                // console.log("payload:" + payload);
                Axios.post('api/emailtest/', payload).then(response => {
                    if (response.status === 200) {
                        // console.log("response.data: " + response.data);
                        resolve(response.data);
                    } else {
                        //  console.log("response err: " + response.status);


                        reject(response.data.msg);

                    }
                }).catch(function (error) {
                    console.log(error);
                    context.commit('setLoader_visible', false);
                    Vue.prototype.$swal({
                        title: "Login Error",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                });
            });


        },

        save(context, payload) {
            return new Promise((resolve, reject) => {
                Axios.post('api/save', payload).then(response => {
                    if (response.status === 200) {
                        // this.commit("setLstPeople", response.data);
                        resolve("Sought Perons Saved!");
                    } else {
                        reject(response.data.msg);
                    }
                }).catch(response => {
                    //unkown why but using satus codes causes the reposne to be wrapped in a repsonse
                    reject(response.data.msg);
                });
            });
        },

    }
});
