<template>
    <v-container id="login-container" class="">

        <Transition name="scroll-y-reverse-transition" mode="out-in">

            <!--Login view-->
            <div v-if="!reg_view_active && !rec_view_active">
                <v-container>
                    <v-img class="mx-auto"
                           max-height="225"
                           max-width="225" contain
                           src="../images/blackwhite_badge_supermin4.png" eager
                           transition="fade-transition">

                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0"
                                   align="center"
                                   justify="center">
                                <v-progress-circular indeterminate
                                                     color="grey lighten-5"></v-progress-circular>
                            </v-row>
                        </template>

                    </v-img>
                </v-container>

                <v-spacer></v-spacer>

                <v-card class="mx-auto"
                        max-width="800" elevation="4">

                    <v-toolbar class="secondary white--text">
                        <v-toolbar-title>
                            Login
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-divider></v-divider>

                    <v-card-text>

                        <div class="deep-orange--text">{{login_message}}</div>
                        <v-form ref="loginform" v-model="login_form_valid">

                            <v-text-field class="login_field" ref="loginEmail" label="*Email" clearable maxlength="100"
                                          placeholder="JohnDoe@reno.gov" :autocomplete="autocomplete"
                                          required persistent-placeholder
                                          v-model="login_form.email"
                                          :rules="[() => !!login_form.email || 'This field is required']">
                            </v-text-field>

                            <v-text-field class="login_field" v-model="login_form.password" clearable maxlength="200"
                                          :append-icon="showpassword ? '$vuetify.icons.eye' : '$vuetify.icons.eyeoff'"
                                          required persistent-placeholder
                                          :type="showpassword ? 'text' : 'password'"
                                          label="*Password"
                                          placeholder="*********"
                                          :rules="[() => !!login_form.password || 'This field is required']"
                                          @click:append="showpassword = !showpassword" @keydown.enter="keydownLogin">

                            </v-text-field>

                        </v-form>

                        <!--<v-divider class="my-2"></v-divider>-->

                        <v-row>
                            <v-col class="text-center">
                                <v-btn color="primary" @click="submitLogin" class="mx-auto">
                                    Login
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row class="text-center mt-8" no-gutters>

                            <v-col cols="6" md="4">
                                <v-btn color="primary--text" class="btn-multiline" @click="activate_rec_view">
                                    <span class="text-wrap">Forgot your password?</span>
                                </v-btn>
                            </v-col>

                            <v-divider vertical></v-divider>

                            <v-col cols="6" md="4">
                                <v-btn color="primary--text" class="btn-multiline" @click="activate_reg_view">
                                    <span class="text-wrap"> Need an account?</span>
                                </v-btn>
                            </v-col>

                            <v-divider vertical v-if="!$vuetify.breakpoint.smAndDown"></v-divider>

                            <v-col cols="12" md="4">
                                <v-btn color="primary--text" class="btn-multiline" @click="help_diag_active = true">
                                    <span class="text-wrap">Need more help?</span>
                                </v-btn>
                            </v-col>

                        </v-row>

                    </v-card-text>
                </v-card>

                <v-overlay absolute :value="overlay_loading">
                    <v-progress-circular indeterminate :size="80" :width="7"
                                         color="primary"></v-progress-circular>
                </v-overlay>
            </div>

            <!--Registration View-->
            <reg v-else-if="reg_view_active && !rec_view_active" @cancel_reg="cancel_reg"></reg>
            <!--Recovery View-->
            <rec v-else-if="rec_view_active && !reg_view_active" @cancel_rec="cancel_rec"></rec>


        </Transition>

        <helpdialog v-if="help_diag_active" @closeDialog="close_help_dialog">
        </helpdialog>

    </v-container>
</template>


<style lang="scss">
    .btn-multiline > span {
        width: 100%
    }

    .btn-multiline {
        height: 50px !important;
        background-color: transparent !important;
        box-shadow: none;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    #login-container {
        max-width: 1185px;
    }
</style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    export default {
        name: "Login",
        mixins: [bus_common],
        props: ["navigation", "rec_view", "reg_view", "help_active"],
        components: {
            reg: () => import('./reg.vue'),
            rec: () => import('./rec.vue'),
            helpdialog: () => import('./helpdialog.vue')
        },
        data() {
            return {
                showpassword: false,
                login_form_valid: false,
                overlay_loading: false,
                login_form: {
                    login_eventId: 0,
                    email: "",
                    password: ""
                },
                reg_view_active: this.reg_view,
                rec_view_active: this.rec_view,
                help_diag_active: this.help_active,
                // disabled by default, to prevent browsers autofill when navigating backwards after submission
                autocomplete: 'off',
                login_message: "",
                redirect_message: "After you log in, you'll be redirected to the app you were trying to access.",
                autofill: false


            };
        },
        methods: {
            async init() {
                this.overlay_loading = true;

                //On init, decode message from URL if provided
                let msgURL = this.$route.query.portalmsg;
                if (msgURL != undefined) {
                    this.login_message = decodeURI(msgURL);

                }

                //If using a returnURL, apply message to inform users they will be redirected
                let returnURL = this.$route.query.returnURL;
                if (returnURL != undefined) {
                    if (this.login_message.length > 1) {
                        this.login_message = this.login_message + " " + this.redirect_message;
                    }
                    else {
                        this.login_message = this.redirect_message;
                    }

                }

                var form = { returnURL: "" }
                await this.$axios.post('api/login/init', form)
                    .then(response => {
                        this.login_form.login_eventId = response.data;
                    }).catch(error => {
                        if (error.response) {
                            this.swall_it("Error!", error.response.data.msg, "error", null, null);
                        }
                    });

                this.overlay_loading = false;
            },
            keydownLogin() {
                if (this.login_form_valid)
                    this.submitLogin();
            },
            async submitLogin() {

                if (!this.login_form_valid) {
                    this.$swal({
                        title: "Error!",
                        text: "Please enter a valid username and password.",
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#283593',
                        timer: 4000,
                        didClose: null
                    });
                    return;
                }

                //Check for route trans param
                let sourceURL = this.$route.query.sourceURL;
                let returnURL = this.$route.query.returnURL;
                let param = this.$route.query.param;
                let pid = this.$route.query.pid;
                let refresh_callback = () => { window.location.reload(); }

                //If params are present, call transfoer login_event controller
                if (returnURL != undefined) {

                    this.overlay_loading = true;

                    let form = JSON.parse(JSON.stringify(this.login_form));
                    form.sourceURL = sourceURL;
                    form.returnURL = returnURL;

                    if (param == undefined)
                        form.param = "";
                    else
                        form.param = param;

                    if (pid == undefined)
                        form.pid = "";
                    else
                        form.pid = pid;

                    await this.$axios.post('api/login/login_event_trans', form)
                        .then(response => {
                            console.log(response.data)
                            window.location.replace(response.data);

                        }).catch(error => {
                            if (error.response) {
                                //If the error message asks for a page refresh, refresh page
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: refresh_callback
                                    });
                                }

                                //If not, the error does not require a page refresh
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });
                    this.overlay_loading = false;

                }

                //If they are not, use normal login flow
                else {
                    this.overlay_loading = true;
                    let form = JSON.parse(JSON.stringify(this.login_form));
                    await this.$axios.post('api/login/login_event', form)
                        .then(response => {
                            let token = response.data;

                            this.$router.push({ path: "/" + this.navigation, query: { token: token } });

                        }).catch(error => {
                            if (error.response) {

                                //If the error message asks for a page refresh, refresh page
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: refresh_callback
                                    });
                                }

                                //If not, the error does not require a page refresh
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }


                            }
                        });
                    this.overlay_loading = false;
                }


            },
            activate_reg_view() {
                this.reg_view_active = true;
            },
            cancel_reg() {
                this.reg_view_active = false;
            },
            activate_rec_view() {
                this.rec_view_active = true;
            },
            cancel_rec() {
                this.rec_view_active = false;
            },
            open_help_dialog() {
                this.help_diag_active = true;
            },
            close_help_dialog() {
                this.help_diag_active = false;
            },
            clear_store() {

                //Check here that if store has data, clear it
                //This would occur if the back arrow is used
                //If the user has a token, clear store
                if (this.$store.state.user.token != '') {

                    var user = {
                        email: '',
                        roles: '',
                        token: '',
                        rpduserId: 0,
                        local_roleId: -1
                    };
                    this.$store.commit("setUser", user);

                    //Causes page refresh, disable for now
                    //Timeout will execute as normal
                    ////Clear timeout timers
                    //this.$store.commit("setTime_remaining_warn_mills", null);
                    //this.$store.commit("setTime_remaining_final_mills", null);

                    console.log("The store has been cleared!")
                }

                //If the store is empty do nothing


            },
            clear_autofill() {
                setTimeout(() => {
                    this.autocomplete = 'on';
                    this.$refs.loginform.reset();
                    console.log("Clear Autofill")
                }, 1000);
            },
            checkAutofillStatus() {
                // Run 100ms
                let intervalDetectAutofill = setInterval(() => {
                    // Query DOM for autofill style
                    if (document.querySelectorAll('input[type="password"]:-webkit-autofill').length > 0) {
                        this.autofill = true
                        clearInterval(intervalDetectAutofill)
                        console.log("Autofill detected")
                    }
                }, 100)

                // if after 1s nothing appear, means no autofill was made
                setTimeout(() => {
                    if (intervalDetectAutofill) {
                        clearInterval(intervalDetectAutofill)
                        intervalDetectAutofill = null
                    }

                    if (!this.autofill) {
                        this.$nextTick(() => {
                            this.$refs.loginform.reset();
                            console.log("No Autofill detected, clear input")
                        });
                    }

                }, 1000)
            },
            async logout_user(email) {
                await this.$axios.get('api/logout?email=' + email)
                    .then(response => {
                        console.log(response.data);
                    }).catch(error => {
                        console.log(error.response);
                    });
            }
        },
        computed: {

        },
        mounted() {

            this.init();
            this.clear_store();
            this.checkAutofillStatus();

            //Check if regeventID is passed, if so activate view
            let regeventId = this.$route.query.regeventId;
            if (regeventId != undefined && parseInt(regeventId) != 0) {
                this.activate_reg_view();
            }

            //Check if recvcode is passed, if so activate rec view
            let recvcode = this.$route.query.recvcode;
            if (recvcode != undefined && parseInt(recvcode) != 0) {
                this.activate_rec_view();
            }

            //Check if recvcode is passed, if so activate rec view
            let logout = this.$route.query.logout;
            if (logout != undefined) {
                this.logout_user(logout);
            }


            this.autocomplete = 'off';
            //document.addEventListener("backbutton", this.clear_autofill(), false);
            // enable after slight delay for the user:
            //setTimeout(() => {
            //    this.$refs.loginform.reset();
            //    console.log("On")
            //}, 1000);
        },
        beforeDestroy() {
            //document.removeEventListener("backbutton", this.yourCallBackFunction);
        }
    };
</script>
